<template>
	<div class="top-bar fix-on-scroll" :class="{ active }">
		<div class="row">
			<div class="columns column12">
				<div v-if="link">
					<nuxt-link :to="localePath(link)" class="full-width no-text-decoration">
						<span>{{ text }}</span>
					</nuxt-link>
				</div>
				<div v-else>
					<span>{{ text }}</span>
				</div>
				<div class="close" @click="close">
					<font-awesome-icon :icon="`fal fa-xmark`" size="1x" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
defineProps({
	text: { type: String, default: '' },
	link: { type: String, default: '' },
});

const active = useState('activeTopBar', () => true);

const close = () => {
	active.value = false;
};
</script>

<style lang="scss" scoped>
.top-bar {
	text-align: center;
	text-transform: uppercase;
	padding: 8px 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1001;
	background: #cddada;
	font-weight: 900;

	.column12 {
		position: relative;
	}

	.full-width {
		width: calc(100% - 80px);
		position: relative;
		margin: 0 auto;
		text-align: center;
		z-index: 1;
		color: var(--body-color);

		&::after {
			background: none;
		}
	}

	.close {
		z-index: 2;
		position: absolute;
		top: 0;
		right: 20px;
		cursor: pointer;

		svg {
			font-size: 24px;
		}
	}
}

@media (max-width: 500px) {
	.top-bar {
		font-size: 13px;

		.column12 {
			display: flex;

			div:not(.close) {
				flex-grow: 1;
			}
		}

		.close {
			position: static;

			svg {
				font-size: 20px;
			}
		}
	}
}
</style>
