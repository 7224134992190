<template>
	<div>
		<top-bar
			v-if="defaults[locale].website.promotionBanner"
			:text="defaults[locale].website.promotionBanner"
			:link="defaults[locale].website.promotionBannerLink"
		/>

		<header
			class="fix-on-scroll"
			:class="{ 'active-top-bar': activeTopBar, 'no-top-bar': !defaults[locale].website.promotionBanner }"
		>
			<header-bar @toggle-nav="toggleNav" @hide-nav="hideNav" />
		</header>

		<fullscreen-nav :show="showNav" @hide-nav="hideNav" />
	</div>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

const showNav = ref(false);
const activeTopBar = useState('activeTopBar', () => true);

const toggleNav = () => {
	showNav.value = !showNav.value;
};

const hideNav = () => {
	showNav.value = false;
};
</script>

<style lang="scss" scoped>
header {
	position: absolute;
	background: var(--primary-background-color);
	padding: 22px 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1002;
	transition: padding 0.3s ease-in-out;

	&.active-top-bar {
		top: 38px;
	}

	&.no-top-bar {
		top: 0;
	}

	&.fixed {
		position: fixed;
		top: 0;
		padding: 20px;
	}
}

@media (max-width: 1200px) {
	header {
		padding: 15px 0;
	}
}
</style>
