<template>
	<div class="divider" />
</template>

<style lang="scss" scoped>
.divider {
	width: 1px;
	height: 46px;
	background: #3c3834;
	margin: 0 30px 0 0;
}
</style>
